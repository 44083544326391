import React from 'react';

const NotFoundPage = () => (
  <>
    <div className="container-404">
      <div className="error-page-wrapper">Unfortunately the page you’re looking for isn’t available right now.</div>
    </div>
  </>
);

export default NotFoundPage;
